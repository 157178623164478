$(function(){
	$('.offerSlider').slick({
		infinite: false,
		dots: true,
		autoplay: true,
	  	autoplaySpeed: 3000,
		slidesToShow: 1,
		slidesToScroll: 1
	});
	$('.popularSlider').slick({
		infinite: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
	        {
	          breakpoint: 992,
	          //сообщает, при какой ширине экрана нужно включать настройки
	          settings: {
	            slidesToShow: 2,
	            slidesToScroll: 1,
	            infinite: true
	          }
	        }
	    ],
	    responsive: [
	        {
	          breakpoint: 576,
	          //сообщает, при какой ширине экрана нужно включать настройки
	          settings: {
	            slidesToShow: 1,
	            slidesToScroll: 1,
	            infinite: true
	          }
	        }
	    ]
	});	
	$('.whySlider').slick({
		infinite: false,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1
	});
	$('.customersSlider').slick({
		infinite: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
	        {
	          breakpoint: 992,
	          //сообщает, при какой ширине экрана нужно включать настройки
	          settings: {
	            slidesToShow: 2,
	            slidesToScroll: 1,
	            infinite: true,
	            autoplay: true
	          }
	        }
	    ],
	    responsive: [
	        {
	          breakpoint: 576,
	          //сообщает, при какой ширине экрана нужно включать настройки
	          settings: {
	            slidesToShow: 1,
	            slidesToScroll: 1,
	            infinite: true,
	            autoplay: true
	          }
	        }
	    ]
	});
	$('.hamburger').click(function(){
		$('.rotateElement1').toggleClass('rotate1');
		$('.rotateElement2').toggleClass('rotate2');
		$('.mobileNav').toggleClass('visible')
	})
	$('.sliderShow').slick({
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  arrows: false,
	  fade: true,
	  asNavFor: '.sideBar'
	});
	$('.sideBar').slick({
	  slidesToShow: 2,
	  slidesToScroll: 1,
	  asNavFor: '.sliderShow',
	  dots: false,
	  centerMode: true,
	  focusOnSelect: true,
	  infinite: false,
	  arrows: false
	});
	/*$('#openMenSuit').click(function(){
		if (function(){

		});
	});*/
	/*$('.collapse').collapse()*/
});